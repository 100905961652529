





















import { Component } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import { post, download } from '@common-src/service/request';
import QuarantineRoomImportComponent from './quarantine-room-import.vue';

@Component({
    components: { QuarantineRoomImportComponent }
})
export default class QuarantineRoomComponent extends BaseComponent {
    async importExcel(importData: any) {
        const url = `${BIBIZ_BASE_REQUEST_PATH}/epidemicPreventionControlSystem/quarantineRoom/status/import`;
        const params = importData;
        const res = await post(url, params, { headers: { 'Content-Type': 'multipart/form-data' }, timeout: 600000 });
        return res;
    }

    async getExcelTemplate() {
        const url = `${BIBIZ_BASE_REQUEST_PATH}/epidemicPreventionControlSystem/quarantineRoom/template`;
        const res = await download(url, null, 'POST');
        this.showMessageSuccess('下载模板成功');
    }

    importExcelClick() {
        (this.$refs.importExcelDialog as QuarantineRoomImportComponent).dialogOpen();
    }
}

