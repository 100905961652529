

















import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { ViewModeType } from '@common-src/model/enum';
import { FormControlType } from '@common-src/model/form-control';
import { post } from '@common-src/service/request';

async function importExcel(importData: any) {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/epidemicPreventionControlSystem/quarantineRoom/status/import`;
    const params = importData;
    const res = await post(url, params, { headers: { 'Content-Type': 'multipart/form-data' }, timeout: 600000 });
    return res;
}

@Component
export default class QuarantineRoomImportComponent extends FormDialogComponent<any> {
    isProtocol: boolean;
    dialogOpen(): void {
        this.dialogVisible = true;
        this.viewMode = ViewModeType.NEW;
        this.dialogTitle = '隔离数据上报';
        this.$nextTick(() => {
            if (this.jtlForm) {
                const controlItems = [
                    {
                        key: 'file',
                        label: '选择Excel文件',
                        type: FormControlType.UPLOAD,
                        accept: '.xlsx',
                        notUploadOss: true,
                        maxFileNumber: 1,
                        required: true
                    }
                ];
                (this.jtlForm as any).initFormByControls(controlItems);
            }
        });
    }

    dialogOK(): any {
        if (!this.jtlForm) {
            return;
        }
        this.startFullScreenLoading('正在导入，请耐心等待......');
        return new Promise((resolve, reject) => {
            this.jtlForm.submitForm().then((ret) => {
                const formData = new FormData();
                formData.append('file', _.get(this.jtlForm.formModel.file, '[0].file'));
                importExcel(formData).then(res => {
                    if (!res || res.er !== -1) {
                        // 导入失败
                        // 文件流 或者 json的er不等于-1
                        this.showMessageError(res?.erMessage || '隔离数据导入失败');
                        resolve(null);
                    } else {
                        // 导入成功，返回json
                        this.showMessageSuccess(res?.items?.message || '隔离数据导入成功');
                        this.dialogClose();
                        this.$emit('dialogOK');
                        resolve(null);
                    }
                }).catch(err => {
                    reject();
                    throw err;
                });
            }).catch(err => {
                reject();
                throw err;
            });
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }
}
