var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-component" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          { staticClass: "page-breadcrumb" },
          [
            _c(
              "a-breadcrumb",
              [
                _c(
                  "a-breadcrumb-item",
                  [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                  1
                ),
                _c("a-breadcrumb-item", [_vm._v("疫情防控")]),
                _c("a-breadcrumb-item", [_vm._v("隔离数据上报")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "page-body page-body-margin" },
        [
          _c("h1", [_vm._v("隔离数据上报")]),
          _vm._m(0),
          _c("br"),
          _c(
            "jtl-button",
            {
              staticStyle: { "margin-right": "32px" },
              attrs: { type: "primary", size: "large" },
              on: { click: _vm.importExcelClick },
            },
            [_vm._v("上传数据")]
          ),
          _c(
            "jtl-button",
            {
              attrs: { type: "primary", size: "large" },
              on: { click: _vm.getExcelTemplate },
            },
            [_vm._v("下载模板")]
          ),
        ],
        1
      ),
      _c("QuarantineRoomImportComponent", { ref: "importExcelDialog" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("1. 只用上传已被使用的隔离房间列表。"),
      _c("br"),
      _vm._v("2. 为避免数据更新异常，不更新的楼栋工作表请勿上传。"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }